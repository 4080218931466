import { Transition } from "@headlessui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Fragment, useState } from "react";

const AccordionItem = ({ heading, content }: { heading: string; content: string; }) => {
    const [folded, setFolded] = useState<boolean>(false);

    return (
        <div className="border-t-[1px] border-royalPeriwinkle items-center">
            <button className="flex justify-between py-4 w-full" type="button" onClick={() => setFolded((prev) => !prev)}>
                <p className="text-body font-semibold text-left w-[calc(100%_-_24px)]">{heading}</p>
                <Icon icon={`tabler:chevron-down`} width={24} height={24} className={`text-accordorange w-6 transition-theme ${folded ? 'rotate-180' : ''}`} />
            </button>
            <Transition
                as={Fragment}
                show={folded}
                enter="transition-theme"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-theme"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <p className="text-12 leading-4 mb-4">
                    {content}
                </p>
            </Transition>
        </div>
    )
}

export default AccordionItem;